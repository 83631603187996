/* eslint-disable camelcase */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/prop-types */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState } from 'react';
import Loader from 'components/Loader';
import { isClientMobile, isClientTablet, isClientTabletPro } from 'utils/detection';
import CheckLoader from 'components/CheckLoader';
import {
  makeSelectEnvVariablesData,
  makeSelectLocation,
  makeSelectLocale,
} from 'containers/App/selectors';

import {
  login,
  signInWithCode,
  register,
  forgot,
  resetpassword,
} from 'utils/firebase';
import {
  required,
  email,
  confirm_password,
  password,
  checkbox,
} from 'utils/validation';
import Grid from 'components/Grid';
import { useDispatch, useSelector } from 'react-redux';
import Toast from 'components/Toast';

import Seo from 'components/Seo';
import { Row, Col, Container } from 'react-bootstrap';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import { createStructuredSelector } from 'reselect';
import {
  authChanged,
  triggerAuthTracker,
  authFailed,
  storePhoneVerificationData,
} from 'containers/AuthProvider/actions';
import { makeSelectAuth } from 'containers/AuthProvider/selectors';
import { makeSelectUsers } from '../App/Users/selectors';
import {
  setToast,
  toggleVerificationModal,
  setFormLoader,
  loadAuthPageContent,
  setLoader,
  loadAuthPageContentSuccess,
} from './actions';
import reducer from './reducer';
import saga from './saga';
import { makeSelectPageContent } from './selectors';
import Elements from 'components/Elements';
import ListLayout from 'components/List/ListLayout';
import LOGINBGIMAGE from './image.png';
import REGISTERBGIMAGE from './registerbgimage.png';
const key = 'authPage';
// eslint-disable-next-line consistent-return
function execFn(fnName, ctx, params) {
  try {
    if (params) {
      return ctx[fnName](...params);
    }
    return ctx[fnName];
  } catch (e) {
    setToast('Something went wrong');
  }
}
const stateSelector = createStructuredSelector({
  page: makeSelectPageContent(),
  location: makeSelectLocation(),
  env: makeSelectEnvVariablesData(),
  users: makeSelectUsers(),
  auth: makeSelectAuth(),
  locale: makeSelectLocale(),
});


const AuthPage = () => {
  useInjectReducer({ key, reducer });
  useInjectSaga({ key, saga });
  const [data, setJsonData] = useState(null);
  const dispatch = useDispatch();
  const {
    page: { toast, params, verificationModal, formLoader },
    auth,
    locale,
    location,
    env,
    users,
    loading
  } = useSelector(stateSelector);
 
  useEffect(() => {
    setLoader(true);
    const loadData = async () => {
      const someData = await import(`./authData_${locale}.json`);
      const dataBasedOnPath = someData.default.find(dt => dt.pathName === location.pathname);
      dispatch(loadAuthPageContentSuccess(dataBasedOnPath));
      setJsonData(dataBasedOnPath);
      setLoader(false);
    };
    loadData();
  }, []);

  useEffect(() => {
    dispatch(loadAuthPageContent());
  }, []);
  const isMobile = isClientMobile();
  const isTablet = isClientTablet();
  const isTabletPro = isClientTabletPro();
  if (isMobile || isTablet || isTabletPro) {
    return (
      <div className="pt-4">
        <Grid
          key="mobileGrid"
          container={[
            {
              key: 'mobilesection',
              type: 'fluid',
              class: 'px-0',
              rows: [
                {
                  id: `mobilesectionrow`,
                  class: 'pt-9',
                  columns: [
                    {
                      class: 'p-9',
                      widths: '',
                      id: `mobilesectioncol`,
                      content: (
                        <>
                          <h1>Midasboard works only on desktop</h1>
                        </>
                      ),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
  return (
    <section id="auth-page">
      {data && <Seo
        url={env.variables.DOMAIN}
        title={data.pageTitle}
        description={data.pageDescription}
        image={data.ogImag}
      />}
      {loading && <Loader />}
      {toast && (
        <Container
          style={{
            position: 'fixed',
            top: 55,
            left: 15,
            zIndex: 3000,
          }}
        >
          <Toast
            autohide
            priority="medium"
            show={!!toast}
            delay={7000}
            message={toast}
            onClose={() => dispatch(setToast(false))}
          />
        </Container>
      )}
      {data &&
        data.section &&
        data.section.map((item, gridkey) => (
          <Grid
            key={`Grid${gridkey}`}
            locale={locale}
            hasbanner
            bannerproperties={{
              size: 'lg',
              variant: 'willow',
              height: '100vh',
              bgImage: data.backgroundImage && LOGINBGIMAGE,
              curve: 'false',
            }}
            container={
              item.container && [
                ...item.container.map(ct => ({
                  class: ct.class,
                  type: 'fluid',
                  key: ct.id,
                  rows: [
                    ...ct.rows.map(row => ({
                      key: `rows${row.id}`,
                      class: row.class,
                      columns: row.columns && [
                        ...row.columns.map(col => ({
                          key: `${col.id}`,
                          class: col.class,
                          widths: col.widths !== "" && col.widths,
                          bgImage: col.hasBgImage ? REGISTERBGIMAGE : '', // only displayed on register page
                          container: [
                            {
                              type: 'fluid',
                              key: `colcontainer${col.id}`,
                              class: col.hasmobilecontent ? '' : 'pt-7',
                              rows: [
                                {
                                  key: `colrow${col.id}`,
                                  columns: [
                                    {
                                      key: `colcol${col.id}`,
                                      content: (
                                        <>
                                          {formLoader && col.hasform && (
                                            <>
                                              <CheckLoader
                                                complete={formLoader === 'tick'}
                                                message={`<h4 class="pb-6">${data.loadermessage}</h4>`}
                                              />
                                            </>
                                          )}

                                          {col.hascontent &&
                                            col.paragraphs &&
                                            col.paragraphs.map(para => (
                                                <Elements
                                                  key={para.id}
                                                  tag={para.tag}
                                                  className={para.class}
                                                >
                                                  {para.content}
                                                </Elements>
                                            ))}

                                          {col.haslist && (
                                            <ListLayout
                                              rawdata={[
                                                ...col.lists.map(
                                                  (list, listin) => ({
                                                    key: `listrow-${list.id}`,
                                                    nonbordered: true,
                                                    columns: [
                                                      {
                                                        key: `listcolSection1-${listin}`,
                                                        classes:
                                                          'mb-n3 border-bottom-0',
                                                        content: (
                                                          <>
                                                            <p className="text-dark">
                                                              <img
                                                                src={list.image}
                                                                alt="listimage"
                                                                width="15px"
                                                                height="15px"
                                                                className="me-3"
                                                              />
                                                              {list.text}
                                                            </p>
                                                          </>
                                                        ),
                                                      },
                                                    ],
                                                  }),
                                                ),
                                              ]}
                                            />
                                          )}

                                          {!formLoader && col.hasimage ? (
                                            <div
                                              className={`${
                                                col.hasmobilecontent
                                                  ? 'd-block d-sm-block d-lg-none mb-4 mt-n4'
                                                  : 'mt-8'
                                              }`}
                                            >
                                              {/* <h3 className="mt-6 mb-4 mb-md-0 mx-0 text-center text-dark">
                                                {locale === 'en'
                                                  ? 'As seen in'
                                                  : 'Comme on le voit dans'}
                                              </h3> */}
                                              <Row className="d-flex justify-content-center">
                                                {col.images &&
                                                  col.images.map(image => (
                                                    <Col xs="4" md="2">
                                                      <img
                                                        src={image.src}
                                                        alt="logos"
                                                        height="auto"
                                                        className="img-fluid mt-n3 mt-md-n5"
                                                      />
                                                    </Col>
                                                  ))}
                                              </Row>
                                            </div>
                                          ) : null}
                                        </>
                                      ),
                                      formLoader,
                                      hasform: !formLoader && col.hasform,
                                      forms: col.forms,
                                      submitFunction: event => {
                                        execFn(
                                          data.urlSlug.toLowerCase(),
                                          {
                                            // context for form
                                            register,
                                            login,
                                            signInWithCode, // TODO: haven't tested this
                                            forgot,
                                            resetpassword,
                                          },
                                          [
                                            event,
                                            {
                                              params,
                                              authFunc: {
                                                triggerAuthTracker,
                                                authChanged,
                                                authFailed, // TODO: Verify if need this
                                                storePhoneVerificationData,
                                                toggleVerificationModal,
                                                setToast,
                                                setFormLoader,
                                                dispatch,
                                              },
                                            },
                                            { wperrors: data.errorlookup },
                                          ],
                                        );

                                        dispatch(authFailed(false)); // TODO: remove ...this resets the error message in the redux store
                                      },
                                      context: {
                                        // context for form fields
                                        email,
                                        password,
                                        confirm_password,
                                        required,
                                      },
                                      errorlookup: data.errorlookup,
                                      currentStep: data.urlSlug.toLowerCase(),
                                    },
                                  ],
                                },
                              ],
                            },
                          ],
                        })),
                      ],
                    })),
                  ],
                })),
              ]
            }
          />
        ))}
      <div id="recaptcha-container" />
    </section>
  );
};

export default {
  component: AuthPage,
};
