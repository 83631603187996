/* eslint-disable react/no-danger */
/* eslint-disable no-else-return */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {
  isClientMobile,
  isClientTablet,
  isClientTabletPro,
} from 'utils/detection';
import { useLocation } from 'react-router-dom';
import Footer from 'components/Footer';
import { useSelector, useDispatch } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Seo from 'components/Seo';
import Grid from 'components/Grid';
import Elements from 'components/Elements';
import { useInjectReducer, useInjectSaga } from 'redux-injectors';
import ListLayout from 'components/List/ListLayout';
import {
  makeSelectLocale,
  makeSelectEnvVariablesData,
  makeSelectFooterState,
} from 'containers/App/selectors';
import Link from 'components/Link';
import Button from 'components/Button';
import NotFound from 'components/NotFound';
import { LOAD_DEFAULT_PAGE_DATA } from './constants';
import { makeSelectDefaultPage } from './selectors';
import reducer from './reducer';
import saga from './saga';
const isMobile = isClientMobile();
const isTablet = isClientTablet();
const isTabletPro = isClientTabletPro();

const stateSelector = createStructuredSelector({
  pagedata: makeSelectDefaultPage(),
  locale: makeSelectLocale(),
  env: makeSelectEnvVariablesData(),
  footerState: makeSelectFooterState(),
});
function DefaultPage() {
  useInjectReducer({ key: 'defaultPage', reducer });
  useInjectSaga({
    key: 'defaultPage',
    saga,
  });
  const dispatch = useDispatch();
  const location = useLocation();
  const [data, setJsonData] = useState(null);

  useEffect(() => {
    dispatch({ type: LOAD_DEFAULT_PAGE_DATA });
  }, []);
  const { env, locale, footerState, pagedata } = useSelector(stateSelector);
  const currentURL = `${env.variables.DOMAIN}${location.pathname}`;

  useEffect(() => {
    const loadData = async () => {
      // This doesn't work locally
      const currentSubdomain = window.location.hostname.split('.')[0];
      const somedata = await import(`./${currentSubdomain}_${locale}.json`);
      const dataBasedOnPath = somedata.default;
      setJsonData(dataBasedOnPath);
    };
    loadData();
  }, []);

  // if (data.error) {
  //   return (
  //     <section className="h-100 min-vh-100 bg-primary d-flex align-items-center">
  //       <NotFound code="502" />
  //     </section>
  //   );
  // }
  if (isMobile || isTablet || isTabletPro) {
    return (
      <div className="pt-4">
        <Grid
          key="mobileGrid"
          container={[
            {
              key: 'mobilesection',
              type: 'fluid',
              class: 'px-0',
              rows: [
                {
                  id: `mobilesectionrow`,
                  class: 'pt-9',
                  columns: [
                    {
                      class: 'p-9',
                      widths: '',
                      id: `mobilesectioncol`,
                      content: (
                        <>
                          <h1>Midasboard works only on desktop</h1>
                        </>
                      ),
                    },
                  ],
                },
              ],
            },
          ]}
        />
      </div>
    );
  }
  return (
    <>
      <section>
        <Seo
          type="article"
          title={
            pagedata.issuer &&
            pagedata.issuer.name &&
            pagedata.issuer.name.toUpperCase()
          }
          description={pagedata.issuer && pagedata.issuer.description.bio}
          image={pagedata.issuer && pagedata.issuer.imageUrl}
          url={currentURL}
        />
      </section>
      <div className="pt-4">
        {data &&
          data.section &&
          data.section.map((item, key) => (
            <>
              {item.hasIssueData && (
                <Grid
                  key={`Grid${key}`}
                  container={
                    pagedata.data && [
                      ...pagedata.data.results.map(result => ({
                        class: 'w-defaultpage-container',
                        key: result.id,
                        rows: [
                          {
                            id: `rows${result.id}`,
                            columns: [
                              {
                                id: `columns${result.id}`,
                                class: 'py-7',
                                widths: JSON.parse('{"md": 9}'),
                                content: (
                                  <>
                                    <Elements
                                      key={result.id}
                                      tag="h2"
                                      className="mb-5 mt-0"
                                      style={{ paddingRight: '30%' }}
                                    >
                                      {result.name}
                                    </Elements>
                                    <Elements
                                      key={result.id}
                                      tag="p"
                                      className="text-black"
                                    >
                                      {result.detail.description}
                                    </Elements>
                                  </>
                                ),
                              },
                              {
                                id: `columns${result.id}`,
                                class: 'py-7',
                                widths: JSON.parse('{"md": 3}'),
                                content: (
                                  <>
                                    <Link
                                      // type="external"
                                      to={`/en/issue/${result.symbol}`}
                                      className="p-0 border-0"
                                      aria-label="Join us at Midasboard"
                                    >
                                      <Button
                                        type="button"
                                        variant="primary"
                                        className="w-100"
                                      >
                                        Explore
                                      </Button>
                                    </Link>
                                  </>
                                ),
                              },
                            ],
                          },
                        ],
                      })),
                    ]
                  }
                />
              )}
              {item.hasIssuerData && (
                <Grid
                  key={`Grid${key}`}
                  container={
                    pagedata.issuer && [
                      {
                        class: 'w-defaultpage-container',
                        key: pagedata.issuer.id,
                        rows: [
                          {
                            id: `rows${pagedata.issuer.id}`,
                            columns: [
                              {
                                id: `columns${pagedata.issuer.id}`,
                                class: 'py-7',
                                widths: JSON.parse('{"md": 9}'),
                                content: (
                                  <>
                                    <Elements
                                      key={pagedata.issuer.id}
                                      tag="h2"
                                      className="mb-5 mt-7"
                                    >
                                      {pagedata.issuer.description.title}
                                    </Elements>
                                    <Elements
                                      key={pagedata.issuer.id}
                                      tag="p"
                                      className="text-black"
                                    >
                                      {pagedata.issuer.description.bio}
                                    </Elements>
                                  </>
                                ),
                              },
                            ],
                          },
                        ],
                      },
                    ]
                  }
                />
              )}
              {!item.hasIssueData && !item.hasIssuerData && (
                <Grid
                  key={`Grid${key}`}
                  hasbanner={item.hasbanner}
                  bannerproperties={{
                    size: 'lg',
                    variant: 'willow',
                    bgImage: pagedata.issuer.imageUrl,
                    height: '65vh',
                    curve: 'false',
                    className: 'mt-6',
                    backgroundColor: 'transparent',
                  }}
                  container={
                    item.container && [
                      ...item.container.map(cont => ({
                        type: cont.type,
                        class: cont.class,
                        key: cont.id,
                        role: cont.role,
                        bgColor: cont.bgColor,
                        rows: [
                          ...cont.rows.map(row => ({
                            id: `rows${row.id}`,
                            class: row.class,
                            columns: row.columns && [
                              ...row.columns.map(col => ({
                                id: `${col.id}`,
                                class: col.class,
                                widths: col.widths && JSON.parse(col.widths),
                                content: (
                                  <>
                                    {col.image && col.linkedinurl ? (
                                      <Link
                                        to={{
                                          pathname: `${col.linkedinurl &&
                                            col.linkedinurl}`,
                                        }}
                                        target="_blank"
                                        className="text-decoration-none"
                                        style={
                                          col.linkedinurl === ''
                                            ? { pointerEvents: 'none' }
                                            : null
                                        }
                                      >
                                        <img
                                          src={col.image}
                                          className={col.imageclass}
                                          alt="curvedimages"
                                        />
                                      </Link>
                                    ) : col.image && !col.linkedinurl ? (
                                      <img
                                        src={col.image}
                                        className={col.imageclass}
                                        alt="curvedimages"
                                      />
                                    ) : (
                                      ''
                                    )}
                                    {/* {col.paragraphs &&
                                    col.paragraphs.map(para => (
                                      <>
                                      {para.id === "issuerBanner" ? <Elements
                                        key={pagedata.issuer.id}
                                        tag={"p"}
                                        className={"display-1"}
                                      >
                                        {pagedata.issuer.name}
                                      </Elements>: <Elements
                                        key={para.id}
                                        tag={para.tag}
                                        className={para.class}
                                      >
                                        {para.content}
                                      </Elements>}</>
                                      ))} */}

                                    {col.haslist && (
                                      <ListLayout
                                        twoCol
                                        rawdata={[
                                          ...col.lists.map((list, listin) => ({
                                            class: 'pt-3',
                                            id: `listrow-${list.id}`,
                                            columns: [
                                              {
                                                id: `listcolSection1-${listin}`,
                                                content: (
                                                  <>
                                                    <div className="d-inline float-start h-100">
                                                      <img
                                                        src={list.image}
                                                        alt="listimage"
                                                        width="30px"
                                                        height="30px"
                                                        className="me-3"
                                                      />
                                                    </div>
                                                    <div className="d-inline">
                                                      {list.text}
                                                    </div>
                                                  </>
                                                ),
                                              },
                                            ],
                                          })),
                                        ]}
                                      />
                                    )}
                                    {col.buttons &&
                                      col.buttons.map((btn, index) => (
                                        <div key={`ifbuttonindex${index}`}>
                                          {btn.buttonlink ? (
                                            <Link
                                              // target="_blank"
                                              type="external"
                                              to={btn.buttonlink}
                                              className="p-0 border-0"
                                              aria-label="Join us at Midasboard"
                                            >
                                              <Button
                                                type={btn.type}
                                                variant={btn.variant}
                                                className="w-100"
                                              >
                                                {btn.label}
                                              </Button>
                                            </Link>
                                          ) : (
                                            <Button
                                              type={btn.type}
                                              variant={btn.variant}
                                              className="w-50"
                                              key={`elseindex${index}`}
                                            >
                                              {btn.label}
                                            </Button>
                                          )}
                                        </div>
                                      ))}

                                    {/* {col.hascalculators && (
                                    <>
                                      <Row>
                                        <Col xs={12} md={6}>
                                          <div className="ms-0 ms-md-n3">
                                            <iframe
                                              src="https://app.calconic.com/api/embed/calculator/6272b96f06173f002ac91ae3"
                                              sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
                                              title="Calconic_ Calculator"
                                              name="Calconic_ Calculator"
                                              scrolling="no"
                                              className="w-100 w-xl-100 w-xxl-50 border-0 outline-none"
                                              height="400px"
                                            ></iframe>
                                          </div>
                                        </Col>
                                        <Col xs={12} md={6}>
                                          <div className="d-flex justify-content-end me-0 me-md-n3">
                                            <iframe
                                              src="https://app.calconic.com/api/embed/calculator/6272b97706173f002ac91ae4"
                                              sandbox="allow-same-origin allow-forms allow-scripts allow-top-navigation allow-popups-to-escape-sandbox allow-popups"
                                              title="Calconic_ Calculator"
                                              name="Calconic_ Calculator"
                                              scrolling="no"
                                              className="w-100 w-xl-100 w-xxl-50 border-0 outline-none"
                                              height="400px"
                                            ></iframe>
                                          </div>
                                        </Col>
                                      </Row>
                                    </>
                                  )} */}
                                  </>
                                ),
                                container: [
                                  {
                                    type: 'fluid',
                                    class: 'p-0',
                                    key: 'container1',
                                    rows: col.rows && [
                                      ...col.rows.map(subrow => ({
                                        id: subrow.id,
                                        class: subrow.class,
                                        columns: subrow.columns && [
                                          ...subrow.columns.map(subcol => ({
                                            id: subcol.id,
                                            class: subcol.class,
                                            widths:
                                              subcol.widths &&
                                              JSON.parse(subcol.widths),
                                            content: (
                                              <>
                                                {subcol.image && (
                                                  <img
                                                    src={subcol.image}
                                                    className={
                                                      subcol.imageclass
                                                    }
                                                    alt="curvedimages"
                                                  />
                                                )}

                                                {/* {subcol.paragraphs &&
                                              subcol.paragraphs.map(para => (
                                                <>
                                                {para.id === "issuerBanner" ? <Elements
                                                  key={pagedata.issuer.id}
                                                  tag={"p"}
                                                  className={"display-1"}
                                                >
                                                  {pagedata.issuer.name}
                                                </Elements>: <Elements
                                                  key={para.id}
                                                  tag={para.tag}
                                                  className={para.class}
                                                >
                                                  {para.content}
                                                </Elements>}</>
                                                ))} */}
                                                {subcol.buttons &&
                                                  subcol.buttons.map(
                                                    (btn, btnin) => (
                                                      <div
                                                        key={`buttonsouter${btnin}`}
                                                      >
                                                        {btn.buttonlink ? (
                                                          <Link
                                                            target="_blank"
                                                            type="external"
                                                            to={btn.buttonlink}
                                                            className="p-0 border-0"
                                                            aria-label="Join us at Midasboard"
                                                          >
                                                            <Button
                                                              type={btn.type}
                                                              variant={
                                                                btn.variant
                                                              }
                                                              className={`${
                                                                subcol.hascalculator
                                                                  ? 'mt-4 mt-md-0 float-xs-start float-md-end w-100 w-md-50'
                                                                  : ''
                                                              } w-50`}
                                                            >
                                                              {btn.label}
                                                            </Button>
                                                          </Link>
                                                        ) : (
                                                          <Button
                                                            type={btn.type}
                                                            variant={
                                                              btn.variant
                                                            }
                                                            className="w-50"
                                                          >
                                                            {btn.label}
                                                          </Button>
                                                        )}
                                                      </div>
                                                    ),
                                                  )}
                                              </>
                                            ),
                                          })),
                                        ],
                                      })),
                                    ],
                                  },
                                ],
                              })),
                            ],
                          })),
                        ],
                      })),
                    ]
                  }
                />
              )}
            </>
          ))}
      </div>

      {footerState.data && (
        <Footer rawdata={footerState.data} locale={locale} />
      )}
    </>
  );
  // }
  // return (
  //   <>
  //     <Seo
  //       type="article"
  //       title={
  //         data ? data.title : getPageName(location.pathname)
  //       }
  //       // description={data.description}
  //       image={data.ogImage}
  //       url={currentURL}
  //     />
  //     <Loader />
  //   </>
  // );
}

export default {
  component: DefaultPage,
};
